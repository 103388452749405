<template>
  <div>
    <el-form
      :model="selectedDocument"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
    <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Государственный номер" prop="number">
            <el-input v-model="selectedDocument.gosnumber">
              <template slot="prepend">№</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Масса" prop="number">
            <el-input v-model="selectedDocument.mass">
              <template slot="prepend">№</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button type="success" @click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "vehiclesEdit",
  props: ["selectedDocument"],
  data() {
    return {
      file: "",
      rules: {
        name: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        number: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    getFileName: {
      get() {
        return this.selectedDocument.filePath !== null
          ? this.selectedDocument.filePath.slice(
              this.selectedDocument.filePath.lastIndexOf("\\") + 1
            )
          : "Документ не загружен";
      },
    },
  },

  methods: {
    deleteDocument() {
      this.selectedDocument.filePath = null;
    },

    changeFile(file, fileList) {
      console.log(fileList);
      this.file = file.raw;
    },

    save() {
      let document = new FormData();
      document.append("gosnumber", this.selectedDocument.gosnumber);
      document.append("mass", this.selectedDocument.mass);

      this.$store
        .dispatch("updatevehicles", document)
        .then((response) => {
          console.log(response);
          this.notification("Успешно", "ТС изменено", "success");
          this.$store.dispatch("getAllvehicles", {
            page: 0,
            size: 20,
          });
          this.$store.dispatch("setShowModal", null);
        });
    },
  },
};
</script>

<style scoped></style>
